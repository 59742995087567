$fontstack: 'Poppins', sans-serif;
$fontbody: 'Open Sans', sans-serif;
$colordefault: #000;
$colorblue: #4A63E7;
$colororange: #FF5959;
$colorred: #FE3A4A;
$coloryellow: #FEC501;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}